import React from 'react';
import Navigation from '../components/navigation/Navigation';
import Body from '../components/body/Body.js';

function HomePage() {
  return (
   <div>
    <Navigation/>
    <Body/>
   </div>
  );
}

export default HomePage;
